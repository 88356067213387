




































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import AsyncButton from '@/components/shared/AsyncButton.vue';
import EditTagMenu from '@/components/shared/EditTagMenu.vue';
import DeleteTagMenu from '@/components/shared/DeleteTagMenu.vue';
import { Authenticator, TagBoss } from '@/services';
import { lazyInject } from '@/ioc/inversify.config';
import { TYPES } from '@/ioc/types';
import { ModuleProvider, Hanasu, NavRouter } from '@/services';
import { AxiosResponse } from 'axios';
import { Nullable, TagEvent, RequestID } from '@/types';
import { SHENGCI_MODULES, VUE_LIFECYCLE_EVENT } from '@/enums';
import AppMain from '@/AppMain';
import { AppStage, TagRequest, TagManager, ComponentID, TagResult, Tag, NewTagData } from '@/types';
import { take, first, takeUntil, mapTo, startWith, distinctUntilChanged, share, filter, map, debounceTime, tap, throttle } from 'rxjs/operators';
import { Observable, timer, merge, combineLatest, Subscription, Subject, from } from 'rxjs';
import TagChip from '@/components/shared/TagChip.vue';
import { generateComponentID, generateRequestID } from '@/helpers/helpers';

type EditMode = "new" | "edit";

@Component({
  components: {
    AsyncButton, TagChip, EditTagMenu, DeleteTagMenu
  }
})
export default class TagsListComponent extends Vue implements TagManager
{  
  @lazyInject(TYPES.AUTHENTICATOR_INSTANCE)
  private authenticator!: Authenticator;

  @lazyInject(TYPES.MODULE_PROVIDER_INSTANCE)
  private module_provider!: ModuleProvider;

  @lazyInject(TYPES.NAVROUTER_INSTANCE)
  private nav_router!: NavRouter;

  @lazyInject(TYPES.HANASU_INSTANCE)
  private hanasu!: Hanasu;

  @lazyInject(TYPES.TAGBOSS_INSTANCE)
  private tagboss!: TagBoss;

  public id: ComponentID = generateComponentID();
  public readonly module_type: SHENGCI_MODULES = SHENGCI_MODULES.TAGS;
  public tags: Array<Tag> = [];
  public search_text: string = "";
  public loading: boolean = true;
  public delay_before_loader: number = 600;
  public min_loader_duration: number = 600;
  public loader_subscription: Nullable<Subscription> = null;
  public request_subscription: Nullable<Subscription> = null;
  public tag_requests$: Subject<TagRequest> = new Subject<TagRequest>();
  public tag_results$: Subject<TagResult> = new Subject<TagResult>();
  public dialog: boolean = false;
  public pending: boolean = false;
  public mode: EditMode = "new";
  public tag: Nullable<Tag> = null;
  public last_tag_request_id: number = 0;
  public inited: boolean = false;
  public page: number = 1;
  public total: number = 0;

  @Watch('page')
  onPageChange(): void {
    this.requestTags({
      request_id: generateRequestID(),
      page: this.page,
    });
  }

  length(): number {
    return Math.ceil(this.total / 10);
  }

  getSelf(): TagManager {
    return this;
  }

  created(): void {
      //wait till we're logged and app is ready before loading tags
      const app_ready$ = AppMain.app_stage_events$.pipe(first((app_stage: AppStage)=>app_stage=="authenticated"),take(1));
      app_ready$.subscribe({
        next: (app_stage: AppStage) => {
          this.initTagQuery();
          this.requestTags({
            request_id: generateRequestID(),
            page: this.page,
          });
        }
      });
  }

  @Watch('search_text')
  onSearchTextChange(search_text: string): void {
    const request_id: RequestID = generateRequestID(); 
    this.requestTags({
      request_id: request_id,
      page: this.page,
    });
  }

  deleteTag(tag: Tag): Observable<TagEvent> {
    const request_id: RequestID = generateRequestID(); 
    this.requestTags({
      request_id: request_id,
      page: this.page,
      delete_tags: [tag.id]
    });
    return this.tag_results$.pipe(
      filter( (tag_result: TagResult) => {
        return tag_result.request_id == request_id;
      }),
      take(1),
      map( (tag_result: TagResult) => {
        return ({
          type: "deleted"
        } as TagEvent);
      })
    );
  }

  closeEditTagMenu(): void {
    this.tag = null;
    this.dialog = false;
  }

  openNewTagMenu(): void {
    this.tag = null;
    this.mode = "new";
    this.dialog = true;
  }

  openEditTagMenu(tag: Tag): void {
    this.tag = tag;
    this.mode = "edit";
    this.dialog = true;
  }

  updateTag(tag_id: number, tag_name: string, tag_description: string): Observable<TagEvent> {
    const request_id: RequestID = generateRequestID(); 
    this.requestTags({
      request_id: request_id,
      page: this.page,
      edit_tag: {
        tag_id: tag_id,
        tag_data: {name: tag_name, description: tag_description}
      }
    });
    return this.tag_results$.pipe(
      filter( (tag_result: TagResult) => {
        return tag_result.request_id == request_id;
      }),
      take(1),
      map( (tag_result: TagResult) => {
        return ({
          type: "updated"
        } as TagEvent);
      })
    );
  }


  newTag(tag_name: string, tag_description: string): Observable<TagEvent> {
    const request_id: RequestID = generateRequestID(); 
    this.requestTags({
      request_id: request_id,
      page: this.page,
      new_tag: {name: tag_name, description: tag_description}
    });
    return this.tag_results$.pipe(
      filter( (tag_result: TagResult) => {
        return tag_result.request_id == request_id;
      }),
      take(1),
      map( (tag_result: TagResult) => {
        return ({
          type: "added"
        } as TagEvent);
      })
    );
  }

  initTagQuery(): void {
    let query_debounced$: Observable<TagRequest> = this.tag_requests$.pipe(
      debounceTime(500),
      tap((tag_request: TagRequest) => {
        //console.log('debounced')
    }));
    let query_throttled$: Observable<TagRequest> = this.tag_requests$.pipe(
      throttle(() => query_debounced$),
      tap((tag_request: TagRequest) => {
        //console.log('throttled')
      })
    );

    let tag_request_dethrottled$: Observable<TagRequest> = merge(query_throttled$, query_debounced$).pipe(
      filter((tag_request: TagRequest) => {
        if(this.last_tag_request_id <= 0) {
          //console.log('here1');
          //console.log(`last id: ${this.last_tag_request_id}`);
          this.last_tag_request_id = tag_request.request_id;
          return true;
        }
        if(this.last_tag_request_id !== tag_request.request_id) {
          //console.log('here2');
          //console.log(`last id: ${this.last_tag_request_id}`);
          //console.log(`current id: ${tag_request.request_id}`);
          this.last_tag_request_id = tag_request.request_id;
          return true;
        }
        //console.log('here3');
        return false;
      })
    );

    this.request_subscription = tag_request_dethrottled$.subscribe({
      next: (tag_request: TagRequest) => {
        //console.log('tag_request');
        //this.tags.length = 0;
        //this.tags.splice(this.tags.length);
        const get_tags$ = this.tagboss.requestTags(tag_request).pipe(take(1),share());
        const off_timer$: Observable<number> = timer(this.min_loader_duration);
        const showLoadingIndicator$ = combineLatest(
          // OFF in X seconds
          get_tags$.pipe( mapTo(false)),
          // OFF once we receive a result
          off_timer$.pipe( mapTo(false) )
        )
        .pipe(
          mapTo(false),
          startWith(true),
          distinctUntilChanged(),
          take(2)
        );
        this.loader_subscription = showLoadingIndicator$.subscribe({
          next: (is_loading: boolean) => {
            //console.log(`loader: ${is_loading}`);
            this.loading = is_loading;
          },
          complete: () => {
            this.loader_subscription?.unsubscribe();
            this.loader_subscription = null;
          }}
        );
        const tags_subscription: Subscription = get_tags$.subscribe({
          next: (tag_results: TagResult) => {
            this.inited = true;
            this.tag_results$.next(tag_results);
            /* if(tag_results.tags.length <= 0) this.no_tags = true; */
            this.tags = [];
            //if(tag_results.page) this.page = tag_results.page;
            this.total = tag_results.total;
            this.tags.splice(this.tags.length);
            tag_results.tags.forEach((tag: Tag) => {
              this.tags.push(tag);
            });
          },
          complete: () => {
            tags_subscription.unsubscribe();
          }
        });
      } 
    });
  }

  cleanUp(): void {
    if(this.loader_subscription) this.loader_subscription.unsubscribe();
    if(this.request_subscription) this.request_subscription.unsubscribe();
  }

  destroyed(): void {
    this.cleanUp();
  }

  unmounted(): void {
    this.cleanUp();
  }

  requestTags(tag_request: TagRequest): void {
    if(this.search_text?.length > 0) {
      tag_request.search = this.search_text;
    }
    this.tag_requests$.next(tag_request);
  }
}
