





































import { Component, Vue, Prop, Watch, Emit, PropSync } from 'vue-property-decorator';
import AsyncButton from '@/components/shared/AsyncButton.vue';
import { Authenticator, TagBoss } from '@/services';
import { lazyInject } from '@/ioc/inversify.config';
import { TYPES } from '@/ioc/types';
import { ModuleProvider, Hanasu, NavRouter } from '@/services';
import { AxiosError, AxiosResponse } from 'axios';
import { Nullable, TagManager, Tag, TagEvent, NewTagData, SimpleValidationResult, TagRequest, VueForm } from '@/types';
import { SHENGCI_MODULES, VUE_LIFECYCLE_EVENT } from '@/enums';
import AppMain from '@/AppMain';
import { AppStage } from '@/types';
import { take, first, takeUntil, mapTo, startWith, distinctUntilChanged, share, debounceTime, switchMap, catchError, tap } from 'rxjs/operators';
import { Observable, timer, merge, combineLatest, Subscription, Subject, of } from 'rxjs';
import TagChip from '@/components/shared/TagChip.vue';
import { InputValidationRule } from 'vuetify';
import { generateComponentID, generateRequestID } from '@/helpers/helpers';

type EditMode = "new" | "edit";
type STag = Tag;
type STagManager = TagManager;
type SNewTagData = NewTagData;

@Component({
  components: {
    AsyncButton, TagChip
  }
})
export default class EditTagMenu extends Vue
{  
  @lazyInject(TYPES.AUTHENTICATOR_INSTANCE)
  private authenticator!: Authenticator;

  @lazyInject(TYPES.MODULE_PROVIDER_INSTANCE)
  private module_provider!: ModuleProvider;

  @lazyInject(TYPES.NAVROUTER_INSTANCE)
  private nav_router!: NavRouter;

  @lazyInject(TYPES.HANASU_INSTANCE)
  private hanasu!: Hanasu;

  @lazyInject(TYPES.TAGBOSS_INSTANCE)
  private tagboss!: TagBoss;

  public tag_name_rules: Array<InputValidationRule> = [
    (tag_name: string) => !!tag_name || 'Tag must have a name',
    (tag_name: string) => (tag_name && tag_name.length <= 500 || 'Tag name must be 500 characters or less'),
    (tag_name: string) => (tag_name && tag_name.trim().length >= 1) || 'Tag must have a name',
    (tag_name: string) => (tag_name && this.isTagUnique()) || this.tagValidationMessage()
  ];

  @PropSync('dialog', {type: Boolean}) public dialog_synced!: boolean;
  public tag_name: string = "";
  public tag_description: string = "";
  @Prop({default: "new"}) mode!: EditMode;
  @Prop() tag!: STag;
  @Prop() owner!: STagManager;
  public pending: boolean = false;
  public tag_name_is_unique: boolean = true;
  public tag_form_is_valid: boolean = true;
  public tag_validation_requests$: Subject<TagRequest> = new Subject<TagRequest>();
  public tag_validation_sub: Subscription = new Subscription();
  public tag_validation_message: string = "Invalid tag";
  public tag_form: Nullable<VueForm> = null;

  getEditTagForm(): Nullable<VueForm> {
    if(this.tag_form) {
      return this.tag_form;
    } else {
      if(this.$refs.tag_form as VueForm) {
        this.tag_form = (this.$refs.tag_form as VueForm);
        return this.tag_form;
      }
      //console.log('tag_form not found!');
      return null;
    }
  }

  validateTagEditForm(): boolean {
    const _tag_form: Nullable<VueForm> = this.getEditTagForm();
    if(_tag_form) {
      _tag_form.validate();
      return true;
    }
    return false;
  }
  
  resetTagEditForm(): boolean {
    const _tag_form: Nullable<VueForm> = this.getEditTagForm();
    if(_tag_form) {
      _tag_form.reset();
      return true;
    }
    return false;
  }
  
  resetValidationTagEditForm(): boolean {
    const _tag_form: Nullable<VueForm> = this.getEditTagForm();
    if(_tag_form) {
      _tag_form.resetValidation();
      return true;
    }
    return false;
  }

  tagValidationMessage(): string {
    return this.tag_validation_message;
  }

  isTagUnique(): boolean {
    return this.tag_name_is_unique;
  }

  created(): void {
    this.tag_validation_sub = this.tag_validation_requests$.pipe(
      tap( (tag_request: TagRequest) => {
        //console.log('tag_request');
        this.tag_name_is_unique = true;
      }),
      debounceTime(500),
      switchMap( (tag_request: TagRequest) => {
        return this.tagboss.validateTag(tag_request).pipe(
          catchError( (error: AxiosError) => {
          const validation_result: SimpleValidationResult = {
            valid: false,
          };
          if(error.response?.data?.errors?.name) {
            this.tag_name_is_unique = false;
            this.tag_validation_message = error.response.data.errors.name[0];
            this.validateTagEditForm();
          } else {
            validation_result.error = error;
          }
          return of(validation_result);
        })
        );
      }),
    )
    .subscribe({
      next: (validation_result: SimpleValidationResult) => {
        this.validateTagEditForm();
      },
      error: (error: AxiosError) => {
        console.log('some error ocurred');
      }
    });
  }

  @Watch('dialog')
  modeChanged(new_mode: EditMode): void {
    if(this.mode == "edit" && this.tag) {
        this.tag_name = this.tag.name;
        this.tag_description = this.tag.description;
    } else {
      this.tag_name = "";
      this.tag_description = "";
    }
    this.pending = false;
    this.tag_name_is_unique = true;
    this.tag_form_is_valid = true;
    this.resetValidationTagEditForm();
  }

  @Watch('tag_name')
  tagNameChanged(new_tag_name: string): void {
    if(!this.tag_name || this.tag_name.length <= 0) return;
    const tag_request: TagRequest = {
      request_id: generateRequestID()
    };
    if(this.mode == "edit") {
      tag_request.edit_tag = {
        tag_id: this.tag.id,
        tag_data: {
          name: this.tag_name,
          description: this.tag_description,
        }
      };
    } else {
      tag_request.new_tag = {
        name: this.tag_name,
        description: this.tag_description,
      };
    }
    this.tag_validation_requests$.next(tag_request);
  }

  @Emit()
  onClose(): boolean {
    this.tag_name_is_unique = true;
    this.tag_form_is_valid = true;
    this.resetTagEditForm();
    this.tag_validation_sub.unsubscribe();
    return true;
  }

  onSave(): void {
    this.validateTagEditForm();
    this.$nextTick( () => {
      if(this.tag_form_is_valid) {
        this.pending = true;
        if(this.mode == "new") {
          const new_tag_sub: Subscription = this.owner.newTag(this.tag_name, this.tag_description).subscribe({
            next: (tag_event: TagEvent) => {
              this.pending = false;
              this.dialog_synced = false;
              this.onClose();
              new_tag_sub.unsubscribe();
            }
          });
        } else if(this.mode == "edit") {
          const update_tag_sub: Subscription = this.owner.updateTag(this.tag.id, this.tag_name, this.tag_description).subscribe({
            next: (tag_event: TagEvent) => {
              this.pending = false;
              this.dialog_synced = false;
              this.onClose();
              update_tag_sub.unsubscribe();
            }
          });
        }
      }
    });
  }

}
