


























import { Component, Vue, Prop } from 'vue-property-decorator';
import AsyncButton from '@/components/shared/AsyncButton.vue';
import { Authenticator, TagBoss } from '@/services';
import { lazyInject } from '@/ioc/inversify.config';
import { TYPES } from '@/ioc/types';
import { ModuleProvider, Hanasu, NavRouter } from '@/services';
import { AxiosResponse } from 'axios';
import { Nullable, Tag, TagManager } from '@/types';
import { SHENGCI_MODULES, VUE_LIFECYCLE_EVENT } from '@/enums';
import AppMain from '@/AppMain';
import { AppStage, TagEvent } from '@/types';
import { take, first, takeUntil, mapTo, startWith, distinctUntilChanged, share } from 'rxjs/operators';
import { Observable, timer, merge, combineLatest, Subscription } from 'rxjs';
import TagChip from '@/components/shared/TagChip.vue';

type STag = Tag;
type STagManager = TagManager;

@Component({
  components: {
    AsyncButton, TagChip
  }
})
export default class DeleteTagMenu extends Vue
{  
  @lazyInject(TYPES.AUTHENTICATOR_INSTANCE)
  private authenticator!: Authenticator;

  @lazyInject(TYPES.MODULE_PROVIDER_INSTANCE)
  private module_provider!: ModuleProvider;

  @lazyInject(TYPES.NAVROUTER_INSTANCE)
  private nav_router!: NavRouter;

  @lazyInject(TYPES.HANASU_INSTANCE)
  private hanasu!: Hanasu;

  @lazyInject(TYPES.TAGBOSS_INSTANCE)
  private tagboss!: TagBoss;

  public dialog: boolean = false;
  public tag_name: string = "";
  @Prop() tag!: STag;
  @Prop() owner!: STagManager;
  public pending: boolean = false;

  created(): void {
    //console.log(this.tag);
    //console.log(this.owner);
  }

  onSave(): void {
    this.pending = true;
    this.owner.deleteTag(this.tag).subscribe({
      next: (tag_event: TagEvent) => {
          this.pending = false;
          this.dialog = false;
        }
    });
  }
}
